<template>
    <div>
        <v-btn :loading="loading.url" small class="ml-2 lighten-2" v-if="updateMode" @click.stop="printContract('contract')">
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <template v-if="salesTypeId == 5">
            <v-btn
                    :loading="loading.urlProformaInvoiceDualCurrency"
                    small
                    class="ml-3 lighten-2"
                    v-if="updateMode && [1,2].includes(salesStatusId)"
                    @click.stop="printContract('proforma-invoice-dual-currency')"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') + ' ' + contractCurrency + '-' + officeCurrency }}</span>
            </v-btn>
            <v-btn
                    :loading="loading.urlProformaInvoice"
                    small
                    class="ml-3 lighten-2"
                    v-if="updateMode && [1,2].includes(salesStatusId)"
                    @click.stop="printContract('proforma-invoice')"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') + ' ' + officeCurrency }}</span>
            </v-btn>
        </template>
        <template v-else>
            <v-btn
                    :loading="loading.urlProformaInvoice"
                    small
                    class="ml-3 lighten-2"
                    v-if="updateMode && [1,2].includes(salesStatusId)"
                    @click.stop="printContract('proforma-invoice')"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>
            </v-btn>
        </template>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import { api } from "Api"
import {mapGetters} from "vuex";
import PDFMerger from "pdf-merger-js";

export default {
    name: "PrintContractButtons",
    props: ['contractId','updateMode','salesStatusId','contractNumber','salesTypeId','contractCurrencyId','officeCurrencyId','mergeableDocs'],
    data() {
        return {
            contractCurrency: 'USD',
            loading: {
                urlProformaInvoiceDualCurrency: false,
                urlProformaInvoice: false,
                url: false
            },
            officeCurrency: 'MYR'
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        hasMergeableDocs() {
            if(Array.isArray(this.mergeableDocs) && this.mergeableDocs.length > 0){
                return true
            }
            return false
        }
    },
    methods: {
        getPrintPdfUrl(type = 'contract') {
            let urlComponent = "/print-url/contract/"
            if(type == 'proforma-invoice') urlComponent = "/print-url/contract-pi/"
            if(type == 'proforma-invoice-dual-currency') urlComponent = "/print-url/contract-pi-dc/"
            return new Promise((resolve, reject) => {
                api
                    .get(urlComponent + this.contractId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printContract(type = 'contract') {
            switch(type){
                case 'proforma-invoice':
                    this.loading.urlProformaInvoice = true
                    break;
                case 'proforma-invoice-dual-currency':
                    this.loading.urlProformaInvoiceDualCurrency = true
                    break;
                case 'contract':
                default:
                    this.loading.url = true;
                    break;
            }
            this.getPrintPdfUrl(type)
                .then(response => {
                    if (response.data.status == 'success') {
                        if(type == 'contract' && this.hasMergeableDocs) {
                            const mainPdf = response.data.url
                            const mergePdfs = async () => {
                                const merger = new PDFMerger();

                                //update remove doubles in mergeableDocs
                                const mergeableDocs = [...new Set(this.mergeableDocs)];

                                const items = [mainPdf, ...mergeableDocs]
                                for (const file of items) {
                                    await merger.add(file)
                                }
                                const mergedPdf = await merger.saveAsBuffer()
                                return mergedPdf
                            }
                            mergePdfs()
                                .then((pdfBuffer) => {
                                    const url = window.URL.createObjectURL(new Blob([pdfBuffer], {type: 'application/pdf'}));
                                    const link = document.createElement('a');
                                    const thisDocument = this.contractNumber
                                    link.href = url;
                                    link.target = '_blank'
                                    link.setAttribute('download', thisDocument + '.pdf');
                                    link.click()
                                    link.remove();
                                    window.URL.revokeObjectURL(url);
                                    this.loading.url = false
                                })
                                .catch(() => {
                                    this.loading.url = false
                                    this.$toast.error(this.$t('message.errors.pdfError'), {
                                        timeout: 1000,
                                        x: 'center',
                                        y: 'top'
                                    })
                                })
                        } else {
                            let tab = window.open(response.data.url, '_blank');
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                            if(this.loading.url) this.loading.url = false
                            if(this.loading.urlProformaInvoice) this.loading.urlProformaInvoice = false
                            if(this.loading.urlProformaInvoiceDualCurrency) this.loading.urlProformaInvoiceDualCurrency = false
                        }
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        if(this.loading.url) this.loading.url = false
                        if(this.loading.urlProformaInvoice) this.loading.urlProformaInvoice = false
                        if(this.loading.urlProformaInvoiceDualCurrency) this.loading.urlProformaInvoiceDualCurrency = false
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    this.loading.url = false
                    if(this.loading.urlProformaInvoice) this.loading.urlProformaInvoice = false
                    if(this.loading.urlProformaInvoiceDualCurrency) this.loading.urlProformaInvoiceDualCurrency = false
                })
        }
    },
    watch: {
        contractCurrencyId(val) {
            const contractCurrency = this.allCurrencies.find(c => c.Currency.id == val)
            if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
        },
        officeCurrencyId(val) {
            const officeCurrency = this.allCurrencies.find(c => c.Currency.id == val)
            if(officeCurrency) this.officeCurrency = officeCurrency.Currency.code
        }
    },
    created(){
        const contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.contractCurrencyId)
        const officeCurrency = this.allCurrencies.find(c => c.Currency.id == this.officeCurrencyId)
        if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
        if(officeCurrency) this.officeCurrency = officeCurrency.Currency.code
    },
    mounted(){
        this.$nextTick(() => {
            const contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.contractCurrencyId)
            const officeCurrency = this.allCurrencies.find(c => c.Currency.id == this.officeCurrencyId)
            if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
            if(officeCurrency) this.officeCurrency = officeCurrency.Currency.code
        })
    }
}
</script>

<style scoped>

</style>